// Polyfills

// Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
import '@angular/localize/init';

// Zone.js - all (http://kangax.github.io/compat-table/esnext/#test-zones)
import 'zone.js';

(window as any).global = window;
// eslint-disable-next-line @typescript-eslint/no-var-requires
(window as any).global.Buffer = (window as any).global.Buffer || require('buffer/').Buffer;
